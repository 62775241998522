const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
        api: "https://vsipsmartcity-api.cgis.asia/",
  },
  production: {
    api: "https://vsipsmartcity-api.cgis.asia/",
  },
};

module.exports = apiEnvironment[env];
